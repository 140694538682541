import React, {useState, useEffect} from "react";
import "../css/editProduct.css";
import Sidebar from "../../components/js/sidebar";
import axios from "axios";
import { useParams } from "react-router-dom";
import 'animate.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck, faDeleteLeft, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


export default function EditProduct() {

  const navigate = useNavigate();
    const {productId, id, batchId} = useParams();
    const [product, setProduct] = useState({});
    const [image, setImage] = useState("data:image/jpeg;base64,");
    const [isEditing, setIsEditing] = useState(false);


    useEffect(() => {
        axios
          .get(
            `${process.env.REACT_APP_URL}/product-endpoints/product/${productId}/${id}/${batchId}`
          )
          .then((response) => {
            setProduct(response.data.product);
            setImage(image + response.data.image);
          })
          .catch((error) => {
            console.log(error);
          });
    }, [id, batchId]);

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImage(reader.result);
        };
    }

    const handleDelete = async () => {
        const res = await axios
          .delete(
            `${process.env.REACT_APP_URL}/product-endpoints/deleteProduct/${id}/${batchId}`
          )
          .then((res) => {
            console.log(res);
            navigate("/admin/all_products");
          })
          .catch((err) => {
            console.log(err);
          });
    }

    const handleEditProduct = async () => {
      const formData = new FormData();

      const img = await fetch(image);
      const blob = await img.blob();

      formData.append("image", blob, product.imgName);
      formData.append("id", id);
      formData.append("productId", product.productId);
      formData.append("name", product.name);
      formData.append("batchId", product.batchId);
      formData.append("date", product.date);
      formData.append("country", product.country);
      formData.append("imageId", product.imageId);

      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
         await axios.post(
          `${process.env.REACT_APP_URL}/product-endpoints/updateProduct`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((res) => {
          navigate("/admin-panel/all_products");
          // console.log(res);
        })
        .catch((err) => {
          alert("an error has occured")
        });
    }

    return (
      <div className="editProduct">
        <Sidebar />
        <h1 className="animate__animated animate__fadeInDown title">Edit Product</h1>
        <div className="row">
          <div className="animate__animated animate__fadeInLeft col-lg-6 col-md-6 col-sm-6 imageCol">
            <img src={image} />
            <label for="fileInput">
              <FontAwesomeIcon
                icon={faUpload}
                size="xl"
                className="uploadImage"
              />
              Upload Image
            </label>
            <input onChange={(e) => handleChangeImage(e)} disabled={!isEditing} id="fileInput" className="imageInput" type="file" />
          </div>
          <div className="aniamte__animated animate__fadeInRight col-lg-6 col-md-6 col-sm-6 infoCol">
            <div className="buttonGroup">
              <button onClick={() => setIsEditing(true)} className="acrtionButton animate__animated animate__fadeInRightBig">
                Edit Product
                <FontAwesomeIcon icon={faPenToSquare} size="xl" />
              </button>
              <button
                className={`acrtionButton animate__animated animate__fadeInRightBig ${
                  !isEditing ? "buttonDisabled" : ""
                }`}
                disabled={!isEditing}
                onClick={handleEditProduct}
              >
                Commit Changes
                <FontAwesomeIcon icon={faCheck} size="xl" />
              </button>
              <button onClick={handleDelete} className="acrtionButton animate__animated animate__fadeInRight">
                Delete Product
                <FontAwesomeIcon icon={faDeleteLeft} size="xl" />
              </button>
            </div>
            <div className="info animate__animated animate__fadeInRight">
              <label>Product ID</label>
              <input
                onChange={(e) => setProduct({ ...product, productId: e.target.value })}
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="text"
                value={product.productId}
                disabled={!isEditing}
              />
              <label>Product Name</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="text"
                value={product.name}
                disabled={!isEditing}
              />
              <label>BatchId</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, batchId: e.target.value })
                }
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="text"
                value={product.batchId}
                disabled={!isEditing}
              />
              <label>Date Manufactured</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, date: e.target.value })
                }
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="date"
                value={product.date}
                disabled={!isEditing}
              />
              <label>Country Of Origin</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, country: e.target.value })
                }
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="text"
                value={product.country}
                disabled={!isEditing}
              />
              <label>Prodcut URL</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, url: e.target.value })
                }
                className={`${!isEditing ? "disabledInput" : ""}`}
                type="text"
                value={`${process.env.REACT_APP_FRONTEND_URL}/product/${product.productId}/${product.id}/${product.batchId}`}
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>
      </div>
    );
}
