import React, {useState, useEffect} from "react";
import '../css/homePage.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/js/spinner";

export default function Root() {
    return (
    <div className="rootHome">
      <img
            src= {require('./bg.png')}
            alt="product image"
            height={"680px"}
            width={"1300px"}
          />
    </div>
    )
}
