import React, { useEffect, useState } from "react";
import "../css/product.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

export default function Product() {

  const {productId, id, batchId} = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get("id");
  // const productId = queryParams.get("productId");
  // const batchId = queryParams.get("batchid");
  const [product, setProduct] = useState({});


  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/product-endpoints/product/${productId}/${id}/${batchId}`
      )
      .then((response) => {
        setProduct(response.data.product);
        setProduct((prevProduct) => ({
          ...prevProduct,
          image: response.data.image,
        }));
      })
      .catch((error) => {
        alert("Error fetching product");
      });
  }, [id, batchId]);

  return (
      <div className="ProdbeltContainer">
      <div className=" row headerImage">
      </div>
      <div className="row prod">
      <h1 className="title">Thank you for buying TVSM Genuine part</h1>
        <div className="imageWrapper">
        <div className="prod-img">
          <img
            src= {require('./genuine.png')}
            alt="product image"
          />
          </div>
          <div className="prod-img">
          <img
            src={`data:image/jpeg;base64,${product.image}`}
            alt="product image"
          />
          </div>
        </div>
        <div className="col-12 productInfo">
        <p>{product.name}</p>
      </div>
      </div>
      <div className="row">
      <div className="col-12 like">
      <img
          src= {require('./like.png')}
          alt="product image"
        />
      </div>
      </div>
      </div>



  );
}
