import React, {useState, useEffect} from "react";
import '../css/belt.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/js/spinner";
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

const Home = () => {
    // Custom hook to get the current location
    const location = useLocation();
  
    // Check if the current location is the home page
    const isHomePage = location.pathname === '/';
    if (isHomePage) {
        document.body.classList.add('home-page');
    }  
}

export default function Belt() {
    return (
    <div className="ProdbeltContainer">
        <div className="headerImage">
        </div>
      <h1 className="title">Thank You buying TVSM Genuine Part</h1>
      <div className="row prod">
        <div className="col-6">
        <div className="prod-img1">
          <img
            src= {require('./genuine.png')}
            alt="product image"
          />
          </div>
        </div>
        <div className="col-6">
          <div className="prod-img">
          <img
            src= {require('./placeholder.png')}
            alt="product image"
          />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 productInfo">
          <p>N3220640 - MIRROR ASSY R-CITY+/PHOENIX</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 like">
        <img
            src= {require('./like.png')}
            alt="product image"
          />
        </div>
      </div>
    </div>
    )
}
