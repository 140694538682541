import React from "react";
import '../css/spinner.css';


export default function Spinner() {
    return (
        <div className="spinnerCont">
            <div className="spinner"></div>
        </div>
    )
}
