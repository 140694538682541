import React, { useState, useEffect } from "react";
import "../css/allProducts.css";
import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faDeleteLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Sidebar from "../../components/js/sidebar";
import { useNavigate } from "react-router-dom";

export default function AllProducts() {
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState([]); // images is an array of objects {id: productId, prodImage: img, qrCode: image}
  const [searchTerm, setSearchTerm] = useState(""); // searchTerm is the product id
  const navigate = useNavigate();
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios
        .get(`${process.env.REACT_APP_URL}/product-endpoints/allProducts`)
        .then((res) => {
          setProducts(res.data.products);
          setImages(res.data.images);
        })
        .catch((err) => {
          console.log(err);
          alert("Error fetching products");
        });
    };
    fetchProducts();
  }, [triggerUseEffect]);

  const handleDelete = async (id, batchId) => {
    const res = await axios
      .delete(
        `${process.env.REACT_APP_URL}/product-endpoints/deleteProduct/${id}/${batchId}`
      )
      .catch((err) => {
        // console.log(err);
        alert("Error deleting product");
      });

      setTriggerUseEffect(!triggerUseEffect);
    // const newProducts = products.filter((product) => {
    //   return product.productId !== id;
    // });

    // setProducts(newProducts);
  };

  const handleNavigateToEdit = (productId, id, batchId) => {
    navigate(`/admin-panel/edit_product/${productId}/${id}/${batchId}`);
  };

  const handleSearch = () => {
    const filteredProducts = products.filter((product) => {
      return product.productId.includes(searchTerm);
    });

    setProducts(filteredProducts);
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length === 0) {
      setTriggerUseEffect(!triggerUseEffect);
    }
  }

  return (
    <div className="allProducts">
      <Sidebar />
      <h1 className="animate__animated animate__fadeInDown title">
        All Products
      </h1>
      <div className="row">
        <div className=" col-12 search">
          <input
            type="text"
            placeholder="Product ID"
            className="animate__animated animate__fadeInRightBig"
            onChange={(e) => handleSearchChange(e)}
          />
          <button onClick={handleSearch} className="animate__animated animate__fadeInRight">
            SEARCH
            <FontAwesomeIcon icon={faSearch} size="lg" className="searchIcon" />
          </button>
        </div>
        <div className="row table">
          <table>
            <thead className="animate__animated animate__fadeInLeft" key='headings'>
              <tr>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Product Image</th>
                <th>Product QR Code</th>
                {/* <th>URL</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 ? (
                products.map((product, index) => {
                  const image = images.find((img) => img.id === product.id);
                  const rowClass = index % 2 === 0 ? "odd" : "even";
                  const rowAnimation = index % 2 === 0 ? "fadeInRight" : "fadeInLeft";
                  return (
                    <tr
                      className={`${rowClass} animate__animated animate__${rowAnimation}`} key={index}
                    >
                      <td>{product.productId}</td>
                      <td>{product.name}</td>
                      <td>
                        <img
                          src={`data:image/jpeg;base64,${image?.prodImage}`}
                          alt="Product Image"
                        />
                      </td>
                      <td>
                        <img
                          src={`data:image/jpeg;base64,${image?.qrCode}`}
                          alt="Product QR Code"
                        />
                      </td>
                      {/* <td className="url">{`${process.env.REACT_APP_FRONTEND_URL}/product/${product.productId}/${product.id}/${product.batchId}`}</td> */}
                      <td className="lastChild">
                        <button
                          onClick={() =>
                            handleNavigateToEdit(product.productId, product.id, product.batchId)
                          }>
                          Edit Product
                          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                        </button>
                        <button
                          onClick={() =>
                            handleDelete(product.id, product.batchId)
                          }
                        >
                          Delete Product
                          <FontAwesomeIcon icon={faDeleteLeft} size="lg" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="noProducts animate__animated animate__fadeInUp">
                  <h1>No Products Found !</h1>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
