import React, { useState } from "react";
import "../css/sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faPlus,
  faHome,
  faList,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebarHeader">
        <h1 className="logo">Logo</h1>
        <FontAwesomeIcon
          style={!isOpen && { display: "none" }}
          onClick={() => setIsOpen(false)}
          icon={faXmark}
          className="closeIcon"
          size="xl"
        />
        <FontAwesomeIcon
          style={isOpen && { display: "none" }}
          onClick={() => setIsOpen(true)}
          icon={faBars}
          className="closeIcon"
          size="xl"
        />
      </div>
      <div className="sidebarBody">
        <ul className="sidebarList">
          <li
            className="sidebarListItem"
            onClick={() => {
              navigate("/admin-panel");
              setIsOpen(false);
            }}
          >
            <span className="sidebarIcon">Home Page</span>
            <FontAwesomeIcon icon={faHome} size="xl" />
          </li>
          <li
            className="sidebarListItem"
            onClick={() => {
              navigate("/admin-panel/add_product");
              setIsOpen(false);
            }}
          >
            <span className="sidebarIcon">Add Product</span>
            <FontAwesomeIcon icon={faPlus} size="xl" />
          </li>
          <li
            className="sidebarListItem"
            onClick={() => {
              navigate("/admin-panel/all_products")
              setIsOpen(false);
            }}
          >
            <span className="sidebarIcon">All Products</span>
            <FontAwesomeIcon icon={faList} size="xl" />
          </li>
        </ul>
      </div>
      <div
        className="sidebarFooter"
        style={!isOpen ? { opacity: 0 } : { opacity: 1 }}
        onClick={handleLogout}
      >
        <h1 className="logout">Logout</h1>
      </div>
    </div>
  );
}
