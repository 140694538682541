import React, {useState, useEffect} from "react";
import '../css/login.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/js/spinner";

export default function Login() {

    const navigate = useNavigate();
    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        username: "",
        password: ""
    });

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL}/users-endpoints/validate_token`, {
            token: localStorage.getItem('token')
        })
        .then(res => {
            navigate('/admin-panel/dashboard');
        })
        .catch(err => {
        });
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_URL}/users-endpoints/login`, user)
        .then(res => {
            localStorage.setItem('token', res.data.token);
            navigate('/admin-panel/dashboard');
        })
        .catch(err => {
            setLoading(false);
            setLoginFailed(true);
        })
    }

    return (
        <div className="login">
            <div className="loginContainer">
                <h1>Login</h1>
                <form>
                    <input className={`${loginFailed && "invalid"}`} onChange={(e) => setUser({...user, username: e.target.value})} type="text" placeholder="Username" />
                    <input className={`${loginFailed && "invalid"}`} onChange={(e) => setUser({...user, password: e.target.value})} type="password" placeholder="Password" />
                    <p style={{display: (!loginFailed) && "none"}}>Invalid username or password</p>
                    <button onClick={(e) => handleSubmit(e)} type="submit">
                        {loading? <Spinner/> : "Login"}
                    </button>
                </form>
            </div>
        </div>
    )
}
