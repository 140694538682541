import React, { useState } from "react";
import "../css/addProduct.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "../../components/js/spinner";
import "animate.css";
import Sidebar from "../../components/js/sidebar";

export default function AddProduct() {
  const [image, setImage] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    id: "",
    name: "",
    batchId: "",
    date: "",
    country: "",
    imgName: "",
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setProduct({ ...product, imgName: file.name });

    reader.onloadend = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const submitProduct = async () => {
    setLoading(true);
    const formData = new FormData();

    const img = await fetch(image);
    const blob = await img.blob();

    formData.append("image", blob, product.imgName);
    formData.append("productId", product.id);
    formData.append("name", product.name);
    formData.append("batchId", product.batchId);
    formData.append("date", product.date);
    formData.append("country", product.country);

    for (let pair of formData.entries()) {
      // console.log(pair[0] + ", " + pair[1]);
    }

    for(let i in product){
      if(product[i] === "" || product[i] === true){

        console.log(i, product[i])
        product[i] = ""
        console.log(product[i])
        setProduct({...product })
      }
    }
    try {
      if (
        product.id !== "" &&
        product.imgName !== "" &&
        product.name !== "" &&
        product.batchId !== "" &&
        product.date !== "" &&
        product.country !== ""
      ) {
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/product-endpoints/createProduct`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(res);
        setQrCode(res.data.qrCode);
        setLoading(false);
      } else {
        // setProduct(product);
        alert('Please fill required fields')
      }
    } catch (err) {
      alert("an error has occured");
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="addProduct">
      <Sidebar />
      <h1 className="title animate__animated animate__fadeInDown">
        {" "}
        Add New Product
      </h1>
      <div className="row body">
        <div className="animate__animated animate__fadeInLeft col-lg-4 col-md-12 col-sm-12">
          <form>
            <label>
              Product ID{" "}
            </label>
            <input
              onChange={(e) => {
                setProduct({ ...product, id: e.target.value });
                console.log(e.target.value);
              }}
              type="text"
              placeholder="Product ID"
            />
            <label>
              Product Name
            </label>
            <input
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
              type="text"
              placeholder="Product Name"
            />
            <label>
              Batch ID
            </label>
            <input
              onChange={(e) =>
                setProduct({ ...product, batchId: e.target.value })
              }
              type="text"
              placeholder="Batch ID"
            />
            <label>
              Date Manufactured
            </label>
            <input
              onChange={(e) => setProduct({ ...product, date: e.target.value })}
              type="date"
              placeholder="Date Manufactured"
            />
            <label>
              Country Of Origin
            </label>
            <input
              onChange={(e) =>
                setProduct({ ...product, country: e.target.value })
              }
              type="text"
              placeholder="Country Of Origin"
            />
          </form>
        </div>
        <div className="animate__animated animate__fadeInUp col-lg-4 col-md-12 col-sm-12 productImageUpload">
          <h1>
            Upload Product Image
          </h1>
          <label
            className="imageDiv"
            style={{ backgroundImage: `url(${image})` }}
          >
            <FontAwesomeIcon icon={faUpload} size="xl" className="uploadIcon" />
            <h6 style={image && { display: "none" }}>Click to Select Image</h6>
            <input
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </label>
          <button onClick={submitProduct} className="btn btn-primary">
            {"Add Product"}
          </button>
        </div>
        <div className="animate__animated animate__fadeInRight col-lg-4 col-md-12 col-sm-12 qrCode">
          {qrCode && <img src={qrCode} alt="QR Code" />}
          {/* <img src={require("../../img/main_image_star-forming_region_carina_nircam_final-5mb.jpeg")} alt="qrcode" /> */}
        </div>
      </div>
    </div>
  );
}
